import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219'),
	() => import('./nodes/220'),
	() => import('./nodes/221'),
	() => import('./nodes/222'),
	() => import('./nodes/223'),
	() => import('./nodes/224'),
	() => import('./nodes/225'),
	() => import('./nodes/226'),
	() => import('./nodes/227'),
	() => import('./nodes/228'),
	() => import('./nodes/229'),
	() => import('./nodes/230'),
	() => import('./nodes/231'),
	() => import('./nodes/232'),
	() => import('./nodes/233'),
	() => import('./nodes/234'),
	() => import('./nodes/235'),
	() => import('./nodes/236'),
	() => import('./nodes/237'),
	() => import('./nodes/238'),
	() => import('./nodes/239'),
	() => import('./nodes/240'),
	() => import('./nodes/241'),
	() => import('./nodes/242'),
	() => import('./nodes/243'),
	() => import('./nodes/244'),
	() => import('./nodes/245'),
	() => import('./nodes/246'),
	() => import('./nodes/247'),
	() => import('./nodes/248'),
	() => import('./nodes/249'),
	() => import('./nodes/250'),
	() => import('./nodes/251'),
	() => import('./nodes/252'),
	() => import('./nodes/253'),
	() => import('./nodes/254'),
	() => import('./nodes/255'),
	() => import('./nodes/256')
];

export const server_loads = [2,4,8,13,14,10,11,12,16,19,22,23,25,27,31,35,36,33,34,39,41,45,46,47,49,50,53,54,55,58,62,66,64,65];

export const dictionary = {
		"/(app)": [~68,[2],[3]],
		"/(app)/[[lang=lang]]/(main)/admin": [~69,[2,4,6],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/activities": [~70,[2,4,6],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/applicants": [71,[2,4,6],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/applicants/[type]": [~72,[2,4,6],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/applicants/[type]/[id]": [73,[2,4,6,8],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/applicants/[type]/[id]/contact-history": [74,[2,4,6,8],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/applicants/[type]/[id]/curriculum-vitae": [75,[2,4,6,8],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/applicants/[type]/[id]/jobs": [76,[2,4,6,8],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers": [~77,[2,4,6,9],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/stores": [90,[2,4,6,9],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/stores/[id]": [~91,[2,4,6,13],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/stores/[id]/employees": [92,[2,4,6,13],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/stores/[id]/employees/[userId]": [93,[2,4,6,14],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/stores/[id]/job-history": [94,[2,4,6,13],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/stores/[id]/open-jobs": [95,[2,4,6,13],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/stores/[id]/points-of-contact": [96,[2,4,6,13],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]": [~78,[2,4,6,10],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/job-postings": [79,[2,4,6,10],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/points-of-contact": [80,[2,4,6,10],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/price-catalog": [~81,[2,4,6,10],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/store-managers": [82,[2,4,6,10],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/stores": [~83,[2,4,6,10],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]": [~84,[2,4,6,11],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]/employees": [85,[2,4,6,11],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]/employees/[userId]": [86,[2,4,6,12],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]/job-history": [87,[2,4,6,11],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]/open-jobs": [88,[2,4,6,11],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]/points-of-contact": [89,[2,4,6,11],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/dashboard": [~97,[2,4,6],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/hubs": [98,[2,4,6,15],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/hubs/certify-hub": [~99,[2,4,6,15],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/hubs/health-pass": [100,[2,4,6,15],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/job-postings": [101,[2,4,6,16],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/job-postings/[type=job_posting_type]": [102,[2,4,6,16],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/job-postings/[type=job_posting_type]/[id]": [103,[2,4,6,17],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/job-postings/[type=job_posting_type]/[id]/messages": [104,[2,4,6,17],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/job-postings/[type=job_posting_type]/[id]/points-of-contact": [105,[2,4,6,17],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/platform-settings": [~106,[2,4,6,18],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/platform-settings/admins": [107,[2,4,6,18],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/platform-settings/products": [108,[2,4,6,18],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/platform-settings/products/[productId]": [~109,[2,4,6,18],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/profile": [~110,[2,4,6],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/recruiters": [111,[2,4,6,19],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/recruiters/non-eu": [113,[2,4,6,19],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/recruiters/teams": [114,[2,4,6,19],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/recruiters/[recruiterId]": [~112,[2,4,6],[3,5,7]],
		"/(app)/agb": [255,[2],[3]],
		"/(app)/[[lang=lang]]/(main)/applicant": [~115,[2,4,20],[3,5,21]],
		"/(app)/[[lang=lang]]/(main)/applicant/home": [116,[2,4,20,22],[3,5,21]],
		"/(app)/[[lang=lang]]/(main)/applicant/home/curriculum-vitae": [117,[2,4,20,22],[3,5,21]],
		"/(app)/[[lang=lang]]/(main)/applicant/profile": [~118,[2,4,20],[3,5,21]],
		"/(app)/[[lang=lang]]/auth": [~250,[2,67],[3]],
		"/(app)/[[lang=lang]]/auth/confirm": [~251,[2],[3]],
		"/(app)/[[lang=lang]]/auth/set-password": [~252,[2,67],[3]],
		"/(app)/[[lang=lang]]/auth/sign-out": [~253,[2,67],[3]],
		"/(app)/[[lang=lang]]/(main)/common/activities": [119,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/applicants": [120,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/applicants/visa": [122,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/applicants/[id]": [121,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/budgets": [123,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/certify-hub/trackings": [124,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/customers/[customerId]": [125,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/cv": [126,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/employees": [127,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/job-postings": [128,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/job-postings/messages": [131,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/job-postings/points-of-contact": [132,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/job-postings/[id]": [129,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/job-postings/[id]/recruiter": [130,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/points-of-contact": [133,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/profiles": [134,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/stores/[id]": [135,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/customer": [~136,[2,4,23],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/company-settings": [~137,[2,4,23,25],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/dashboard": [138,[2,4,23],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs": [139,[2,4,23],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs/certify-hub": [~140,[2,4,23,26],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs/certify-hub/locations/[storeId]": [~143,[2,4,23,26],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs/certify-hub/[courseId]": [~141,[2,4,23,26,27],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs/certify-hub/[courseId]/[videoId]": [~142,[2,4,23,26,27],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs/health-pass": [~144,[2,4,23,28],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs/health-pass/checkout": [147,[2,4,23,28],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs/health-pass/[type=health_pass_type]": [145,[2,4,23,28],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs/health-pass/[userId]": [146,[2,4,23,28],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/profile": [~148,[2,4,23],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/recruiting": [~149,[2,4,23,29],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/recruiting/accepted": [150,[2,4,23,29],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/recruiting/job-postings": [~151,[2,4,23,29],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/recruiting/job-postings/[id]": [152,[2,4,23,30],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/recruiting/job-postings/[id]/applicants": [~153,[2,4,23,30],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/recruiting/job-postings/[id]/applicants/[applicant_id]": [154,[2,4,23,31],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/recruiting/job-postings/[id]/messages": [155,[2,4,23,30],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/recruiting/job-postings/[id]/visum": [156,[2,4,23,30],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/stores": [157,[2,4,23,32],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/employees": [163,[2,4,23,32],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/employees/[id]": [164,[2,4,23,35],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/store-managers": [165,[2,4,23,32],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/store-managers/[userId]": [166,[2,4,23,36],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/store-managers/[userId]/stores": [167,[2,4,23,36],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/[id]": [~158,[2,4,23,33],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/[id]/employees": [159,[2,4,23,33],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/[id]/employees/[userId]": [160,[2,4,23,34],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/[id]/job-history": [161,[2,4,23,33],[3,5,24]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/[id]/open-jobs": [162,[2,4,23,33],[3,5,24]],
		"/(app)/datenschutz": [256,[2],[3]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter": [~168,[2,4,37],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/applicants": [169,[2,4,37],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/applicants/[type]": [170,[2,4,37],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/applicants/[type]/[id]": [171,[2,4,37,39],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/applicants/[type]/[id]/contact-history": [172,[2,4,37,39],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/applicants/[type]/[id]/curriculum-vitae": [173,[2,4,37,39],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/applicants/[type]/[id]/jobs": [174,[2,4,37,39],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/dashboard": [175,[2,4,37],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/job-postings": [176,[2,4,37],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/job-postings/[type=job_posting_type]": [177,[2,4,37],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/job-postings/[type=job_posting_type]/[id]": [178,[2,4,37,40],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/job-postings/[type=job_posting_type]/[id]/applicants": [~179,[2,4,37,40],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/job-postings/[type=job_posting_type]/[id]/applicants/[applicant_id]": [180,[2,4,37,41],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/job-postings/[type=job_posting_type]/[id]/applicants/[applicant_id]/contact-history": [181,[2,4,37,41],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/job-postings/[type=job_posting_type]/[id]/applicants/[applicant_id]/curriculum-vitae": [182,[2,4,37,41],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/job-postings/[type=job_posting_type]/[id]/applicants/[applicant_id]/jobs": [183,[2,4,37,41],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/job-postings/[type=job_posting_type]/[id]/messages": [184,[2,4,37,40],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/job-postings/[type=job_posting_type]/[id]/points-of-contact": [185,[2,4,37,40],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/job-postings/[type=job_posting_type]/[id]/visum": [186,[2,4,37,40],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/non-eu-recruiter/profile": [~187,[2,4,37],[3,5,38]],
		"/(app)/[[lang=lang]]/(main)/recruiter": [~188,[2,4,42],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/activities": [189,[2,4,42,44],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/activities/archived": [190,[2,4,42,44],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/activities/todos": [191,[2,4,42,44],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/applicants": [192,[2,4,42],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/applicants/[type]": [193,[2,4,42],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/applicants/[type]/[id]": [194,[2,4,42,45],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/applicants/[type]/[id]/contact-history": [195,[2,4,42,45],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/applicants/[type]/[id]/curriculum-vitae": [196,[2,4,42,45],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/applicants/[type]/[id]/jobs": [197,[2,4,42,45],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/customers": [198,[2,4,42],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/customers/[id]": [199,[2,4,42,46],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/customers/[id]/stores": [200,[2,4,42,46],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/customers/[id]/stores/[storeId]": [201,[2,4,42,47],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/dashboard": [202,[2,4,42],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings": [203,[2,4,42],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]": [204,[2,4,42],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]": [205,[2,4,42,48],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/applicants": [~206,[2,4,42,48],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/applicants/[applicant_id]": [207,[2,4,42,49],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/applicants/[applicant_id]/contact-history": [208,[2,4,42,49],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/applicants/[applicant_id]/curriculum-vitae": [209,[2,4,42,49],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/applicants/[applicant_id]/jobs": [210,[2,4,42,49],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/messages": [211,[2,4,42,48],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/points-of-contact": [212,[2,4,42,48],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/recruiter/profile": [~213,[2,4,42],[3,5,43]],
		"/(app)/[[lang=lang]]/(main)/redirect": [214,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/store-employee": [215,[2,4,50],[3,5,51]],
		"/(app)/[[lang=lang]]/(main)/store-employee/certify-hub": [~216,[2,4,50,52],[3,5,51]],
		"/(app)/[[lang=lang]]/(main)/store-employee/certify-hub/[courseId]": [~217,[2,4,50,52,53],[3,5,51]],
		"/(app)/[[lang=lang]]/(main)/store-employee/certify-hub/[courseId]/[videoId]": [~218,[2,4,50,52,53],[3,5,51]],
		"/(app)/[[lang=lang]]/(main)/store-employee/home": [219,[2,4,50,54],[3,5,51]],
		"/(app)/[[lang=lang]]/(main)/store-employee/home/curriculum-vitae": [220,[2,4,50,54],[3,5,51]],
		"/(app)/[[lang=lang]]/(main)/store-employee/profile": [~221,[2,4,50],[3,5,51]],
		"/(app)/[[lang=lang]]/(main)/store-manager": [~222,[2,4,55],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/dashboard": [223,[2,4,55],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs": [224,[2,4,55],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs/certify-hub": [~225,[2,4,55,57],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs/certify-hub/locations/[storeId]": [~228,[2,4,55,57],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs/certify-hub/[courseId]": [~226,[2,4,55,57,58],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs/certify-hub/[courseId]/[videoId]": [~227,[2,4,55,57,58],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs/health-pass": [~229,[2,4,55,59],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs/health-pass/checkout": [232,[2,4,55,59],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs/health-pass/[type=health_pass_type]": [230,[2,4,55,59],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs/health-pass/[userId]": [231,[2,4,55,59],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/profile": [~233,[2,4,55],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/recruiting": [~234,[2,4,55,60],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/recruiting/accepted": [235,[2,4,55,60],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/recruiting/job-postings": [~236,[2,4,55,60],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/recruiting/job-postings/[id]": [237,[2,4,55,61],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/recruiting/job-postings/[id]/applicants": [~238,[2,4,55,61],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/recruiting/job-postings/[id]/applicants/[applicant_id]": [239,[2,4,55,62],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/recruiting/job-postings/[id]/messages": [240,[2,4,55,61],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/recruiting/job-postings/[id]/visum": [241,[2,4,55,61],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/stores": [242,[2,4,55,63],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/stores/employees": [248,[2,4,55,63],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/stores/employees/[id]": [249,[2,4,55,66],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/stores/[id]": [~243,[2,4,55,64],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/stores/[id]/employees": [244,[2,4,55,64],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/stores/[id]/employees/[userId]": [245,[2,4,55,65],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/stores/[id]/job-history": [246,[2,4,55,64],[3,5,56]],
		"/(app)/[[lang=lang]]/(main)/store-manager/stores/[id]/open-jobs": [247,[2,4,55,64],[3,5,56]],
		"/(app)/[lang=lang]": [~254,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';